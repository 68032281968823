* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'Metropolis';
  src: url('assets/fonts/Metropolis-Thin.woff2') format('woff2');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Metropolis';
  src: url('assets/fonts/Metropolis-ThinItalic.woff2') format('woff2');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Metropolis';
  src: url('assets/fonts/Metropolis-ExtraLight.woff2') format('woff2');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Metropolis';
  src: url('assets/fonts/Metropolis-ExtraLightItalic.woff2') format('woff2');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Metropolis';
  src: url('assets/fonts/Metropolis-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Metropolis';
  src: url('assets/fonts/Metropolis-LightItalic.woff2') format('woff2');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Metropolis';
  src: url('assets/fonts/Metropolis-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Metropolis';
  src: url('assets/fonts/Metropolis-RegularItalic.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Metropolis';
  src: url('assets/fonts/Metropolis-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Metropolis';
  src: url('assets/fonts/Metropolis-MediumItalic.woff2') format('woff2');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Metropolis';
  src: url('assets/fonts/Metropolis-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Metropolis';
  src: url('assets/fonts/Metropolis-SemiBoldItalic.woff2') format('woff2');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Metropolis';
  src: url('assets/fonts/Metropolis-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Metropolis';
  src: url('assets/fonts/Metropolis-BoldItalic.woff2') format('woff2');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Metropolis';
  src: url('assets/fonts/Metropolis-ExtraBold.woff2') format('woff2');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Metropolis';
  src: url('assets/fonts/Metropolis-ExtraBoldItalic.woff2') format('woff2');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Metropolis';
  src: url('assets/fonts/Metropolis-Black.woff2') format('woff2');
  font-weight: 900; /* Heavy = Black */
  font-style: normal;
}

@font-face {
  font-family: 'Metropolis';
  src: url('assets/fonts/Metropolis-BlackItalic.woff2') format('woff2');
  font-weight: 900;
  font-style: italic;
}


