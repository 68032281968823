#hourglass-loader {
  --duration: 3s;
  --colour: #111;
  --delay: 2s;

  animation: hourglass-loader var(--duration) var(--delay) cubic-bezier(.8,0,.2,1) infinite;
  height: 40px;
  width: 41px;
  position: absolute;
  top:calc(50% + 100px);
  left:calc(50% - 20px);
}
@keyframes hourglass-loader {
  90% { transform: rotate(0deg); }
  100% { transform: rotate(180deg); }
}
#hourglass-top {
  animation: hourglass-top var(--duration) var(--delay) linear infinite, fadein calc(var(--delay) + 1s);
  border-top: 20px solid var(--colour);
  border-right: 20px solid transparent;
  border-left: 20px solid transparent;
  height: 0px;
  width: 1px;
  transform-origin: 50% 100%;
}
@keyframes hourglass-top {
  90% { transform: scale(0); }
  100% { transform: scale(0);}
}
#hourglass-bottom {
  animation: hourglass-bottom var(--duration) var(--delay) linear infinite;
  border-right: 20px solid transparent;
  border-bottom: 20px solid var(--colour);
  border-left: 20px solid transparent;
  height: 0px;
  width: 1px;
  transform: scale(0);
  transform-origin: 50% 100%;
}
@keyframes hourglass-bottom {
  10% { transform: scale(0); }
  90% { transform: scale(1); }
  100% { transform: scale(1); }
}
#hourglass-line {
  animation: hourglass-line var(--duration) var(--delay) linear infinite;
  border-left: 1px dotted var(--colour);
  height: 0px;
  width: 0px;
  position: absolute;
  top: 20px;
  left: 20px;
}
@keyframes hourglass-line {
  10% { height: 20px; }
  100% { height: 20px; }
}
@keyframes fadein {
    from { opacity: 0; }
    50% { opacity: 0; }
    to   { opacity: 1; }
}
