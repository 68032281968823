/*
  elm-hot creates an additional div wrapper around the app to make HMR possible.
  This could break styling in development mode if you are using Elm UI.

  More context in the issue:
    https://github.com/halfzebra/create-elm-app/issues/320
*/
[data-elm-hot="true"] {
  height: inherit;
}

.editor-toolbar {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
.CodeMirror {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  line-height: 22px;
}
.cm-header-1 {
  font-size: 140% !important;
  line-height: 140% !important;
  font-weight: 400 !important;
}
.cm-header-2 {
  font-size: 120% !important;
  font-size: 120% !important;
  font-weight: 400 !important;
}
.cm-header-3 {
  font-size: 120% !important;
  font-size: 120% !important;
  font-weight: 400 !important;
}

/* Responsive iframes, from https://www.benmarshall.me/responsive-iframes/ */
.iframe-container {
  overflow: hidden !important;
  /* 16:9 aspect ratio */
  padding-top: 56.25% !important;
  position: relative !important;
}

.iframe-container iframe {
  border: 0 !important;
  height: 100% !important;
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
  width: 100% !important;
}

.iframe-container-instagram {
  overflow: hidden !important;
  /* 10:14 aspect ratio seems to fit instagram alright  */
  padding-top: 140% !important;
  position: relative !important;
}

.iframe-container-instagram iframe {
  border: 0 !important;
  height: 100% !important;
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
  width: 100% !important;
}
